import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { IconButton, Tooltip } from '@mui/material';
import { fNumber } from '@/app/utilities/helpers/format-number';
import { bgGradient } from '@/app/theme/css';
import usePopover from '../pop-over/use-popover';
import Iconify from '../iconify';

export default function SummaryCard({
  title,
  total,
  icon,
  color = 'primary',
  hasTooltip,
  tooltip,
  sx,
  ...other
}) {
  const theme = useTheme();
  const moreInfo = usePopover();

  return (
    <Stack
      alignItems="center"
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette[color].light, 0.2),
          endColor: alpha(theme.palette[color].main, 0.2),
        }),
        py: 5,
        borderRadius: 2,
        textAlign: 'center',
        color: `${color}.darker`,
        backgroundColor: 'common.white',
        ...sx,
      }}
      {...other}
    >
      {icon && <Box sx={{ width: 64, height: 64, mb: 1 }}>{icon}</Box>}

      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography variant="h3">{fNumber(total)}</Typography>
        {hasTooltip && (
          <Tooltip title={tooltip} placement="top" arrow>
            <Iconify icon="zondicons:information-outline" width={20} height={20} />
          </Tooltip>
        )}
      </Stack>

      <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
        {title}
      </Typography>
    </Stack>
  );
}
