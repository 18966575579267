import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useCallback } from 'react';
import { Box } from '@mui/material';
import { DEFAULT_DASHBOARD_PERIODS } from '@/app/utilities/consts';

export const DashBoardTabs = ({ currentPeriod, setCurrentPeriod }) => {
  const handleChangeTab = useCallback(
    (event, newValue) => {
      setCurrentPeriod(newValue);
    },
    [setCurrentPeriod]
  );

  return (
    <Box sx={{ height: 35, minHeight: 35 }}>
      <Tabs value={currentPeriod} onChange={handleChangeTab}>
        {DEFAULT_DASHBOARD_PERIODS.map(tab => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            sx={{
              '&:not(:last-of-type)': {
                mr: 3,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
