'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { createContext, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import QueryString from 'qs';
import { useSnackbar } from 'notistack';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { Loading } from '../loading';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { decryptResponse, encryptRequest } from '@/app/utilities/helpers/security';
import { paths } from '@/app/utilities/consts';

export const UserContext = createContext({
  user: {},
  timezone: 'Asia/Shanghai',
  setUser: () => {},
  getUser: () => {},
  setLoading: () => {},
});

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState({});
  const router = useRouter();
  const pathname = usePathname();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [timezone, setTimezone] = useState('Asia/Shanghai');

  const getUser = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const token = localStorage.getItem('accessToken');

      const response = await axiosInstance.post(
        'UsD0YG9TJloNQNXryoiHndBuWa7zKDWs',
        QueryString.stringify(body),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = decryptResponse(response.data);
      if (responseData.success) {
        setUser(responseData.admin);
        setLoading(false);
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
        router.push('/login');
      }
    } catch (e) {
      router.push('/login');
      enqueueSnackbar('Your session is expired', { variant: 'error' });
    }
  }, [enqueueSnackbar, router, setUser]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const localTimeZone = moment.tz.guess();
    setTimezone(localTimeZone);

    if (!token) {
      router.push('/login');
    } else {
      getUser();
    }
  }, [router, getUser]);

  useEffect(() => {
    if (user?.type && user.type !== 'super-admin') {
      if (
        pathname === paths.orders ||
        pathname === paths.resellers ||
        pathname === paths.credits ||
        pathname === paths.appSettings ||
        pathname === paths.languageSettings
      ) {
        router.push('/404');
      }
    }
  }, [pathname, router, user]);

  const value = useMemo(
    () => ({
      user,
      timezone,
      setUser,
      setLoading,
      getUser,
    }),
    [getUser, user, timezone]
  );

  useEffect(() => {
    let timer;
    if (loading) {
      setDisplayLoading(true);
    } else {
      timer = setTimeout(() => {
        setDisplayLoading(false);
      }, 500); // ensure the loading component is visible for at least 1 second
    }

    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <AnimatePresence>
      <>
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
        {(loading || displayLoading) && <Loading />}
      </>
    </AnimatePresence>
  );
}
