/* eslint-disable vars-on-top */
/* eslint-disable radix */
/* eslint-disable camelcase */

function getAtob(text) {
  const result = text;
  try {
    return decodeURIComponent(
      atob(text)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
  } catch (e) {
    console.log('[error from getAtob]', e);
  }
  return result;
}

function getEncryptKey(length) {
  const symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let s = '';
  while (s.length < length) {
    const randomIndex = Math.floor(Math.random() * symbols.length);
    s += symbols.charAt(randomIndex);
  }
  return s;
}

function getEncryptKeyPosition(character) {
  const symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  for (let i = 0; i < symbols.length; i++) {
    if (symbols[i] === character) {
      return i;
    }
  }

  return null;
}
function getEncryptPositionChar(position) {
  const symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  return symbols.charAt(position);
}

exports.encryptRequest = function encryptRequest(data) {
  let string_data = JSON.stringify(data);
  string_data = btoa(string_data);
  let encrypt_key_length = parseInt(Math.random() * 30);
  if (encrypt_key_length < 20) encrypt_key_length = 20;
  let position = Math.floor(Math.random() * string_data.length);
  if (position >= 42) position = 42;
  const encrypt_key = getEncryptKey(encrypt_key_length);
  let encrypted_data = string_data
    .slice(0, position)
    .concat(encrypt_key)
    .concat(string_data.slice(position));
  encrypted_data =
    getEncryptPositionChar(position) + getEncryptPositionChar(encrypt_key_length) + encrypted_data;
  return encrypted_data;
};

exports.decryptResponse = function decryptResponse(data1) {
  let raw_response = data1.data;
  const enc_pos = getEncryptKeyPosition(raw_response[raw_response.length - 2]);
  const enc_len = getEncryptKeyPosition(raw_response[raw_response.length - 1]);
  raw_response = raw_response.slice(0, raw_response.length - 2);
  let encode_str = raw_response.slice(0, enc_pos).concat(raw_response.slice(enc_pos + enc_len));
  encode_str = getAtob(encode_str);
  const data = JSON.parse(encode_str);
  return data;
};
