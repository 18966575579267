const axios = require('axios');

exports.axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  config: {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
});
