/* eslint-disable @next/next/no-img-element */

import { Box, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import QueryString from 'qs';
import { useSnackbar } from 'notistack';
import { useRouter, usePathname } from 'next/navigation';
import SummaryCard from '@/app/components/cards/summary-card';
import SummaryGraph from '@/app/components/cards/summary-graph';
import { encryptRequest, decryptResponse } from '@/app/utilities/helpers/security';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { useUserContext } from '@/app/components/contexts/user-context';

export const SummaryBox = ({ currentPeriod }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [periodData, setPeriodData] = useState({
    totalDevicesCount: {
      all: 0,
      lastMonth: 0,
      last3Months: 0,
      lastYear: 0,
    },
    activatedDevicesCount: {
      all: {
        lifetime: 0,
        oneYear: 0,
      },
      lastMonth: {
        lifetime: 0,
        oneYear: 0,
      },
      last3Months: {
        lifetime: 0,
        oneYear: 0,
      },
      lastYear: {
        lifetime: 0,
        oneYear: 0,
      },
    },
    totalOrdersCount: {
      all: 0,
      lastMonth: 0,
      last3Months: 0,
      lastYear: 0,
    },
    confirmedOrdersCount: {
      all: 0,
      lastMonth: 0,
      last3Months: 0,
      lastYear: 0,
    },
    credits: {
      purchased_credits: 0,
      used_credit: 0,
    },
  });

  const [weeklyData, setWeeklyData] = useState();

  const router = useRouter();
  const path = usePathname();
  const { user } = useUserContext();

  const getPeriodData = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const token = localStorage.getItem('accessToken');
      const response = await axiosInstance.post(
        'upvIWElBqiZPaxlBQcbqzlvNptxH6K5w',
        QueryString.stringify(body),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setPeriodData(responseData.data);
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.setItem('redirectAfterLogin', path);
        router.push('/login');
        return;
      }
      enqueueSnackbar('Server Error', { variant: 'error' });
    }
  }, [enqueueSnackbar, path, router]);

  const getWeeklyData = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const token = localStorage.getItem('accessToken');
      const response = await axiosInstance.post(
        'cOTnuR6iTzT0m0rrNLkgHS8vOIrvqLyj',
        QueryString.stringify(body),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setWeeklyData(responseData.data);
        // Set page Data
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.setItem('redirectAfterLogin', path);
        router.push('/login');
        return;
      }
      enqueueSnackbar('Server Error', { variant: 'error' });
    }
  }, [enqueueSnackbar, path, router]);

  useEffect(() => {
    getPeriodData();
    getWeeklyData();
  }, [getPeriodData, getWeeklyData]);

  return (
    <Box maxWidth="xl">
      <Grid container spacing={4}>
        {user.type === 'super-admin' ? (
          <Grid xs={12} sm={6} md={3} item>
            <SummaryCard
              title="Total Devices"
              total={periodData.totalDevicesCount[currentPeriod]}
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            />
          </Grid>
        ) : (
          <Grid xs={12} sm={6} md={3} item>
            <SummaryCard
              title="Activated Devices"
              total={
                periodData.activatedDevicesCount[currentPeriod].lifetime +
                periodData.activatedDevicesCount[currentPeriod].oneYear
              }
              color="info"
              hasTooltip
              tooltip={`Lifetime activated: ${periodData.activatedDevicesCount[currentPeriod].lifetime}, One year activated: ${periodData.activatedDevicesCount[currentPeriod].oneYear}`}
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
            />
          </Grid>
        )}

        {user.type === 'super-admin' ? (
          <Grid xs={12} sm={6} md={3} item>
            <SummaryCard
              title="Activated Devices"
              total={
                periodData.activatedDevicesCount[currentPeriod].lifetime +
                periodData.activatedDevicesCount[currentPeriod].oneYear
              }
              color="info"
              hasTooltip
              tooltip={`Lifetime activated: ${periodData.activatedDevicesCount[currentPeriod].lifetime}, One year activated: ${periodData.activatedDevicesCount[currentPeriod].oneYear}`}
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
            />
          </Grid>
        ) : (
          <Grid xs={12} sm={6} md={3} item>
            <SummaryCard
              title="Total Credits"
              total={periodData.credits.purchased_credits}
              color="warning"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
            />
          </Grid>
        )}

        <Grid xs={12} sm={6} md={3} item>
          {user.type === 'super-admin' ? (
            <SummaryCard
              title="Total Orders"
              total={periodData.totalOrdersCount[currentPeriod]}
              color="warning"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
            />
          ) : (
            <SummaryCard
              title="Left Credits"
              total={
                (periodData.credits.purchased_credits ?? 0) - (periodData.credits.used_credit ?? 0)
              }
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            />
          )}
        </Grid>

        <Grid xs={12} sm={6} md={3} item>
          {user.type === 'super-admin' ? (
            <SummaryCard
              title="Confirmed Orders"
              total={periodData.confirmedOrdersCount[currentPeriod]}
              color="error"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
            />
          ) : (
            <SummaryCard
              title="Used Credits"
              total={periodData.credits.used_credit}
              color="error"
              icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
            />
          )}
        </Grid>

        {weeklyData && (
          <>
            <Grid xs={12} md={6} lg={6} item>
              <SummaryGraph
                title="Total Devices"
                tooltipUnit="devices"
                chart={{
                  labels: weeklyData?.totalDevices.map(i => i.weekday),
                  series: [
                    {
                      name: 'Total',
                      type: 'area',
                      fill: 'gradient',
                      data: weeklyData?.totalDevices.map(i => i.count),
                    },
                    {
                      name: 'Activated',
                      type: 'area',
                      fill: 'gradient',
                      data: weeklyData?.activatedDevices.map(i => i.count),
                    },
                  ],
                }}
              />
            </Grid>

            <Grid xs={12} md={6} lg={6} item>
              <SummaryGraph
                title="Activated Devices"
                tooltipUnit="devices"
                chart={{
                  labels: weeklyData?.activatedDevices.map(i => i.weekday),
                  series: [
                    {
                      name: 'Activated',
                      type: 'area',
                      fill: 'gradient',
                      data: weeklyData?.activatedDevices.map(i => i.count),
                    },
                    {
                      name: 'Lifetime',
                      type: 'area',
                      fill: 'gradient',
                      data: weeklyData?.activatedLifetimeDevices.map(i => i.count),
                    },
                    {
                      name: 'One year',
                      type: 'area',
                      fill: 'gradient',
                      data: weeklyData?.activatedOneyearDevices.map(i => i.count),
                    },
                  ],
                }}
              />
            </Grid>

            <Grid xs={12} md={6} lg={6} item>
              {user.type === 'super-admin' && (
                <SummaryGraph
                  title="Total Orders"
                  tooltipUnit="orders"
                  chart={{
                    labels: weeklyData?.totalOrders.map(i => i.weekday),
                    series: [
                      {
                        name: 'Total',
                        type: 'area',
                        fill: 'gradient',
                        data: weeklyData?.totalOrders.map(i => i.count),
                      },
                      {
                        name: 'Confirmed',
                        type: 'area',
                        fill: 'gradient',
                        data: weeklyData?.confirmedOrders.map(i => i.count),
                      },
                    ],
                  }}
                />
              )}
            </Grid>

            <Grid xs={12} md={6} lg={6} item>
              {user.type === 'super-admin' && (
                <SummaryGraph
                  title="Confirmed Orders"
                  tooltipUnit="orders"
                  chart={{
                    labels: weeklyData?.confirmedOrders.map(i => i.weekday),
                    series: [
                      {
                        name: 'Confirmed',
                        type: 'area',
                        fill: 'gradient',
                        data: weeklyData?.confirmedOrders.map(i => i.count),
                      },
                    ],
                  }}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
